import Bpage5 from "../../components/blog-pages/Bpage5";

function Page5() {
    return (
        <div>
            <Bpage5></Bpage5>
        </div>
    )

}

export default Page5;