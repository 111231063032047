import Bpage3 from "../../components/blog-pages/Bpage3";

function Page3() {
    return (
        <div>
            <Bpage3></Bpage3>
        </div>
    )

}

export default Page3;