import Mabu from "../../components/mascotes/Mabu";


function MabuPage() {
    return (
        <Mabu></Mabu>
    )
}

export default MabuPage;

