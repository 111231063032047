import Maromba from "../../components/mascotes/Maromba";

function MarombaPage() {
    return (
        <Maromba></Maromba>
    )
}

export default MarombaPage;

