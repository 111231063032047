import Fantasia from "../components/Fantasia";


function Fantasias () {
    return (
        <Fantasia></Fantasia>
    );
}


export default Fantasias;



