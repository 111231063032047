import Bpage4 from "../../components/blog-pages/Bpage4";

function Page4() {
    return (
        <div>
            <Bpage4></Bpage4>
        </div>
    )

}

export default Page4;