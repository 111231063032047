import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner from "../images/banner-modelagem-3d-mascote.jpg";
import bannerMobile from "../images/banner-modelagem-3d-mascote-mobile.jpg";
import banner2 from "../images/banner-Mascote-3D-diversas-poses.jpg";
import banner2Mobile from "../images/banner-Mascote-3D-diversas-poses-mobile.jpg";
import banner3 from "../images/banner-modelagem3D-mascote3D.jpg";
import banner3Mobile from "../images/banner-modelagem3D-mascote3D-mobile.jpg";
import banner4 from "../images/banner-logo3D.jpg";
import banner4Mobile from "../images/banner-logo3D-mobile.jpg";
import "./Carousel.scss"


function CarouselBootstrap() {
  return (
    <Carousel prevIcon={false} nextIcon={false} id="banners">
      <Carousel.Item>
        <picture>
          <source media="(max-width: 800px)" srcSet={bannerMobile} alt="Banner de mascote 3D, com modelagem 360°." />
          <img className="d-block w-100" src={banner} alt="Banner de mascote 3D, com modelagem 360°." />
        </picture>
      </Carousel.Item>
      <Carousel.Item>
        <a href="https://www.oacomunicacaodigital.com.br/logos" target="_blank" rel="noreferrer" title="Acesse nossa página de logos em OA Comunicação Digital">
          <picture>
            <source media="(max-width: 800px)" srcSet={banner4Mobile} alt="Banner de logos OA Comunicação Digital." />
            <img className="d-block w-100" src={banner4} alt="Banner de logos OA Comunicação Digital." />
          </picture>
        </a>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source media="(max-width: 800px)" srcSet={banner3Mobile} alt="Banner de mascote 3D, com modelagem 360°." />
          <img className="d-block w-100" src={banner3} alt="Banner de mascote 3D, com modelagem 360°." />
        </picture>
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source media="(max-width: 800px)" srcSet={banner2Mobile} alt="Banner de mascote 3D, com modelagem 360° em diversas poses." />
          <img className="d-block w-100" src={banner2} alt="Banner de mascote 3D, com modelagem 360° em diversas poses." />
        </picture>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBootstrap;
