import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage3() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Processo de Criação de um Mascote: Etapas Essenciais do Briefing ao Produto Final</h1>
                <p><span>Conteúdo:</span> Criar um mascote é um processo detalhado e personalizado que envolve desde a concepção da ideia até a entrega final. O processo começa com uma reunião de briefing, onde discutimos as necessidades e preferências da marca. Em seguida, desenvolvemos esboços iniciais, que são ajustados e aprovados antes de avançar para a modelagem 3D.</p>
                <p><span>Etapas:</span></p>
                <p><span>1. Briefing Inicial</span> – Identificação dos objetivos e valores da marca.</p>
                <p><span>2. Conceito e Esboço</span> – Criação dos primeiros desenhos e ajustes.</p>
                <p><span>3. Modelagem 3D</span> – Desenvolvimento completo em 3D, com texturas e expressões.</p>
                <p><span>4. Ajustes e Revisão</span> – O cliente participa do processo para assegurar que o mascote está como desejado.</p>
                <p><span>5. Entrega Final</span> – Arquivos em alta qualidade e formatos adequados para uso.</p>
                <p><span>Pergunta Frequente:</span> “Quanto tempo leva para criar um mascote 3D?”<br/>
                <span>Resposta:</span> O processo leva, em média, de 4 a 8 semanas, variando conforme a complexidade e os ajustes necessários. Garantimos um acompanhamento detalhado e revisões que tornam o processo eficaz e transparente.</p>
            </div>
            <NavPage hide={'3'}></NavPage>
        </div>
    )
}

export default Bpage3;