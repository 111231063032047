import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage6() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Por Que Escolher a Mascote Top para Seus Mascotes Habitáveis?</h1>
                <p><span>Opções para Diversos Orçamentos:</span> Desenvolvemos mascotes empresariais e habitáveis para diferentes faixas de preço, atendendo a empresas de todos os portes.</p>
                <p><span>Qualidade e Conforto:</span> Nossos mascotes de vestir são projetados para serem confortáveis e duráveis, ideais para eventos e ações promocionais prolongadas.</p>
                <p><span>Acompanhamento Profissional Completo:</span> Nossa equipe acompanha todas as fases do projeto, garantindo mascotes de alta qualidade e totalmente adaptados às necessidades da sua marca.</p>
                <p>Com a Mascote Top, você recebe mais do que uma fantasia – seu mascote habitável se torna uma experiência que conecta seu público à sua marca. Nossos mascotes empresariais são desenvolvidos com o compromisso de qualidade, conforto e impacto visual, posicionando sua marca de forma marcante e memorável.</p>
            </div>
            <NavPage hide={'6'}></NavPage>
        </div>
    )
}

export default Bpage6;