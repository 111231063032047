import CarouselBootstrap from "../components/Carousel";
import Clientes from "../components/Clientes";
import Sobre from "../components/Sobre";
import Solucoes from "../components/Solucoes";


function HomePage() {
    return (
        <div>
            <CarouselBootstrap/>
            <Clientes></Clientes>
            <Solucoes></Solucoes>
            <Sobre></Sobre>
        </div>
    )
}

export default HomePage;