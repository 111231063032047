import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/rr/mascote-3d-personagem-3d-super-rafaelito-pose-1.jpg'
import image2 from '../../images/mascotes/rr/mascote-3d-personagem-3d-super-rafaelito-pose-2.jpg'
import image3 from '../../images/mascotes/rr/mascote-3d-personagem-3d-super-rafaelito-pose-3.jpg'



function Rafaelito() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Personagem 3D Super-herói, criado para RR Seguros." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Personagem 3D Super-herói, criado para RR Seguros." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Personagem 3D Super-herói, criado para RR Seguros." />
                            </picture>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem: Super Rafaelito</h2>
                    <h3>Cliente: RR Seguros</h3>
                    <h1>Categorias: Mascote, Personagem, Mascote 3D, Personagem 3D, Super-herói 3D, Mascote para empresa.</h1>
                    <h4>Técnica: Modelagem 3D</h4>
                    <p>Criado em: Julho de 2024</p>
                </div>
            </div>

        </div>
    )
}


export default Rafaelito;