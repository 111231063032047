import Sam from "../../components/mascotes/Sam";

function SamPage() {
    return (
        <Sam></Sam>
    )
}

export default SamPage;

