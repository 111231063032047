import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/staffinho/mascote-logo-3d-staffinho-staff-pose-1.jpg'
import image2 from '../../images/mascotes/staffinho/mascote-logo-3d-staffinho-staff-pose-2.jpg'
import image3 from '../../images/mascotes/staffinho/mascote-logo-3d-staffinho-staff-pose-3.jpg'
import image4 from '../../images/mascotes/staffinho/mascote-logo-3d-staffinho-staff-pose-4.jpg'
import image5 from '../../images/mascotes/staffinho/mascote-logo-3d-staffinho-staff-pose-5.jpg'
import image6 from '../../images/mascotes/staffinho/mascote-logo-3d-staffinho-staff-pose-6.jpg'

function Staffinho() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Mascote logo 3D, Staffinho, criado para staff." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Mascote logo 3D, Staffinho, criado para staff." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Mascote logo 3D, Staffinho, criado para staff." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image4} alt="Mascote logo 3D, Staffinho, criado para staff." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image5} alt="Mascote logo 3D, Staffinho, criado para staff." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image6} alt="Mascote logo 3D, Staffinho, criado para staff." />
                            </picture>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem: Staffinho</h2>
                    <h3>Cliente:  Staff do Brasil</h3>
                    <h1>Categorias: Mascote, Mascote Logo, Personagem, Mascote 3D, Personagem 3D, Mascote para empresa.</h1>
                    <h4>Técnica: Modelagem 3D</h4>
                    <p>Criado em: Agosto de 2024</p>
                </div>
            </div>

        </div>
    )
}


export default Staffinho;