import Bpage7 from "../../components/blog-pages/Bpage7";

function Page7() {
    return (
        <div>
            <Bpage7></Bpage7>
        </div>
    )

}

export default Page7;