import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/maromba/maromba-mania-3d-mascote3D-personagem-pose1.jpg';
import image2 from '../../images/mascotes/maromba/maromba-mania-3d-mascote3D-personagem-pose2.jpg';
import image3 from '../../images/mascotes/maromba/maromba-mania-3d-mascote3D-personagem-pose3.jpg';
import image4 from '../../images/mascotes/maromba/maromba-mania-3d-mascote3D-personagem-pose4.jpg';
import image5 from '../../images/mascotes/maromba/maromba-mania-3d-mascote3D-personagem-pose5.jpg';

function Maromba() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Personagem 3D, Mascote 3D Criado para Maromba Mania Pose 1." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Personagem 3D, Mascote 3D Criado para Maromba Mania Pose 2." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Personagem 3D, Mascote 3D Criado para Maromba Mania Pose 3." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image4} alt="Personagem 3D, Mascote 3D Criado para Maromba Mania Pose 4." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image5} alt="Personagem 3D, Mascote 3D Criado para Maromba Mania Pose 5." />
                            </picture>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem 3D</h2>
                    <h3>Cliente:  Maromba Mania</h3>
                    <h1>Categorias: Mascote, Personagem, Mascote 3D, Personagem 3D, Mascote Cartoon, Mascote para empresa.</h1>
                    <h4>Técnica: Modelagem 3D</h4>
                    <p>Criado em: Outubro de 2024</p>
                </div>
            </div>

        </div>
    )
}


export default Maromba;