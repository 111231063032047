import Rai from "../../components/mascotes/Rai";

function RaiPage() {
    return (
        <Rai></Rai>
    )
}

export default RaiPage;

