import Joca from "../../components/mascotes/Joca";

function JocaPage() {
    return (
        <Joca></Joca>
    )
}

export default JocaPage;

