import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage2() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Diferença entre Mascotes 2D e 3D: Qual é Melhor para Sua Empresa?</h1>
                <p><span>Conteúdo:</span> Tanto os mascotes 2D quanto os 3D têm suas vantagens, mas a escolha depende do objetivo da marca e da experiência que deseja proporcionar. Mascotes 2D são mais simples, ideais para designs minimalistas e facilmente adaptáveis a impressos e mídias digitais. Já os mascotes 3D são mais expressivos e versáteis, permitindo maior profundidade e detalhes que tornam a experiência visual muito mais imersiva.</p>
                <p><span>Aplicação:</span> Um mascote 3D pode ser animado para vídeos ou interações virtuais, criando um vínculo mais realista e dinâmico. Para campanhas digitais, essa presença mais tangível faz a diferença, destacando-se especialmente em vídeos e eventos.</p>
                <p><span>Pergunta Frequente:</span> “Minha empresa deve optar por um mascote 3D ou 2D?”<br />
                    <span>Resposta:</span> Depende do que você busca. Se o foco for interação e presença marcante, o 3D é ideal. Já o 2D é excelente para marcas que preferem um visual mais leve e adaptável. Nossa equipe pode ajudar a avaliar a melhor opção para sua marca.</p>
            </div>
            <NavPage hide={'2'}></NavPage>
        </div>
    )
}

export default Bpage2;