import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage1() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Como um Mascote 3D Fortalece a Identidade da Marca</h1>
                <p><span>Conteúdo:</span> O uso de mascotes 3D como estratégia de identidade visual é poderoso para criar uma conexão emocional com o público. A presença de um mascote memorável ajuda a humanizar a marca, tornando-a mais acessível e criando uma relação de proximidade com o público. Quando bem-feito, um mascote se torna uma extensão dos valores e da missão da empresa, representando seu espírito de forma envolvente.</p>
                <p><span>Exemplos:</span> Empresas como a Michelin e o McDonald's utilizam mascotes como símbolo de confiabilidade e familiaridade. Um mascote 3D pode ser ideal para empresas que buscam criar uma identidade inovadora e moderna.</p>
                <p><span>Pergunta Frequente:</span> “Como o mascote pode aumentar o engajamento com o público?” <br />
                    <span>Resposta:</span> Mascotes 3D capturam a atenção e despertam a simpatia do público. Nas redes sociais e campanhas publicitárias, eles podem ser usados em diferentes formatos – desde vídeos curtos a interações personalizadas – aumentando o engajamento e o reconhecimento da marca.</p>
            </div>
            <NavPage hide={'1'}></NavPage>
        </div>
    )
}

export default Bpage1;