import Bpage6 from "../../components/blog-pages/Bpage6";

function Page6() {
    return (
        <div>
            <Bpage6></Bpage6>
        </div>
    )

}

export default Page6;