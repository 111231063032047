import Bpage2 from "../../components/blog-pages/Bpage2";

function Page2() {
    return (
        <div>
            <Bpage2></Bpage2>
        </div>
    )

}

export default Page2;