import Bpage1 from "../../components/blog-pages/Bpage1";


function Page1() {
    return (
        <div>
            <Bpage1></Bpage1>
        </div>
    )

}

export default Page1;