import Compact from "../../components/mascotes/Compact";

function CompactPage() {
    return (
        <Compact></Compact>
    )
}

export default CompactPage;

