import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/rai/rai-personagem-3d-mascote3D-mascote-para-empresa-pose1.jpg';
import image2 from '../../images/mascotes/rai/rai-personagem-3d-mascote3D-mascote-para-empresa-pose2.jpg';
import image3 from '../../images/mascotes/rai/rai-personagem-3d-mascote3D-mascote-para-empresa-pose3.jpg';
import image4 from '../../images/mascotes/rai/rai-personagem-3d-mascote3D-mascote-para-empresa-pose4.jpg';
import image5 from '../../images/mascotes/rai/rai-personagem-3d-mascote3D-mascote-para-empresa-pose5.jpg';
import image6 from '../../images/mascotes/rai/rai-personagem-3d-mascote3D-mascote-para-empresa-pose6.jpg';

function Rai() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Personagem 3D, Mascote 3D RAI, Criado para Rema Tip Top Pose 1." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Personagem 3D, Mascote 3D RAI, Criado para Rema Tip Top Pose 2." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Personagem 3D, Mascote 3D RAI, Criado para Rema Tip Top Pose 3." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image4} alt="Personagem 3D, Mascote 3D RAI, Criado para Rema Tip Top Pose 4." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image5} alt="Personagem 3D, Mascote 3D RAI, Criado para Rema Tip Top Pose 5." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image6} alt="Personagem 3D, Mascote 3D RAI, Criado para Rema Tip Top Pose 6." />
                            </picture>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem: Rai</h2>
                    <h3>Cliente:  Rema Tip Top</h3>
                    <h1>Categorias: Mascote, Personagem, Mascote 3D, Personagem 3D, Mascote Cartoon, Mascote para empresa.</h1>
                    <h4>Técnica: Modelagem 3D</h4>
                    <p>Criado em: Abril de 2024</p>
                </div>
            </div>

        </div>
    )
}


export default Rai;