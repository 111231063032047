import Staffinho from "../../components/mascotes/Staffinho";

function StaffinhoPage() {
    return (
        <Staffinho></Staffinho>
    )
}

export default StaffinhoPage;

